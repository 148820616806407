import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeFilters: [],
  sortType: '',
  filterCategory: '' 
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    addFilter(state, action) {
      state.activeFilters.push(action.payload);
    },
    addFilterCategory(state, action) {
      state.filterCategory = action.payload;
    },
    removeFilter(state, action) {
      state.activeFilters = state.activeFilters.filter(filter => filter !== action.payload);
    },
    removeAllFilters(state) {
      state.activeFilters = [];
      state.filterCategory = ''
    },
    addSorting(state, action) {
      state.sortType = action.payload;
    },
  }
});

export const { addFilter, removeFilter, removeAllFilters, addSorting, addFilterCategory } = searchSlice.actions;

export default searchSlice.reducer;
