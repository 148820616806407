import React, { useState, useEffect, useMemo, useCallback } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Hidden } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilter,
  addFilterCategory,
  removeFilter,
  removeAllFilters,
  addSorting,
} from "../store/reducers/searchSlice";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createSelector } from 'reselect';

// Memoized selector to get active filters
const selectActiveFilters = state => state.search.activeFilters;

export const Search = ({ searchTerm, setSearchTerm }) => {
  const dispatch = useDispatch();
  const activeFilters = useSelector(selectActiveFilters);
  const [sortType, setSortType] = useState("Relevancy");
  const [filterOption, setFilterOption] = useState("All");

  const handleKeyDown = useCallback((event) => {
    if (event.key === "Enter") {
      handleFilterChange(event.target.value);
    }
  }, []);

  const handleDelete = useCallback((filterToRemove) => () => {
    dispatch(removeFilter(filterToRemove));
    setSearchTerm("");
  }, [dispatch, setSearchTerm]);

  const handleFilterChange = useCallback((filter) => {
    if (filter?.toUpperCase() === "ALL") {
      ["Hp", "Lenovo", "Dell", "Microsoft", "Samsung"].forEach(f => dispatch(removeFilter(f)));
      return;
    }
    if (!activeFilters.includes(filter)) {
     // dispatch(addFilterCategory(filter)); // Dispatch addFilterCategory action
      dispatch(addFilter(filter)); // Dispatch addFilter action
    }
  }, [dispatch, activeFilters]);

  const handleFilterOptionChange = useCallback((event) => {
    dispatch(addFilterCategory(event.target.value)); 
    setFilterOption(event.target.value);
    handleFilterChange(event.target.value);
  }, [dispatch, handleFilterChange]);

  const handleSortChange = useCallback((event) => {
    dispatch(addSorting(event)); // Dispatch addSorting action
    setSortType(event);
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        minWidth: 300,
        mt: 2,
        mb: 2,
        ml: 0,
        pl: 0,
      }}
    >
      <Hidden mdDown>
        <Select
          value={filterOption}
          onChange={handleFilterOptionChange}
          sx={{ width: "15%", mr: 1, ml: 0 }}
        >
          <MenuItem value="All">Brand</MenuItem>
          <MenuItem value="hp">Hp</MenuItem>
          <MenuItem value="lenovo">Lenovo</MenuItem>
          <MenuItem value="microsoft">Microsoft</MenuItem>
          <MenuItem value="dell">Dell</MenuItem>
          <MenuItem value="samsung">Samsung</MenuItem>
        </Select>
      </Hidden>
      <TextField
        label="Type your search term and press enter"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        type="search"
        sx={{ width: "60%" }}
        onKeyDown={handleKeyDown}
      />
      <Select
        value={sortType}
        onChange={e => handleSortChange(e.target.value)}
        sx={{ width: "20%", mr: 1, ml: 1 }}
      >
        <MenuItem value="Relevancy">Relevancy</MenuItem>
        <MenuItem value="Price: Low -> High">Price: Low -&gt; High</MenuItem>
        <MenuItem value="Price: High -> Low">Price: High -&gt; Low</MenuItem>
        <MenuItem value="New -> Old">New -&gt; Old</MenuItem>
        <MenuItem value="Old -> New">Old -&gt; New</MenuItem>
        <MenuItem value="Title: A-Z">Title: A-Z</MenuItem>
        <MenuItem value="Title: Z-A">Title: Z-A</MenuItem>
        <MenuItem value="Popularity">Popularity</MenuItem>
      </Select>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          flexWrap: "wrap",
          pt: 2,
        }}
      >
        {activeFilters?.map(filter => (
          <Chip
            color="secondary"
            key={filter}
            label={filter}
            onDelete={handleDelete(filter)}
          />
        ))}

        {activeFilters && activeFilters.length > 0 && (
          <Chip
            color="secondary"
            key="Clear all filters"
            label="Clear all filters"
            onClick={() => {
              dispatch(removeAllFilters());
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Search;
