import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { getProducts } from "../api/products";
import { addToCart } from "../store/reducers/cartSlice";
import Grid from "@mui/material/Grid";
import { loginSuccess } from "store/reducers/authSlice";
import Search from "./Search";
import ProductCard from "./ProductCard";
import Spinner from "./Spinner";
import { createSelector } from 'reselect';

// Memoized selectors
const selectActiveFilters = state => state.search.activeFilters;
const selectSortType = state => state.search.sortType;
const selectFilterCategory = state => state.search.filterCategory;

export const Shopping = () => {
  const [response, setResponse] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const [quantities, setQuantities] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(0);
  const activeFilters = useSelector(selectActiveFilters);
  const sortType = useSelector(selectSortType);
  const filterCategory = useSelector(selectFilterCategory);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search.toLocaleLowerCase());
  const filterParam = urlParams.get('filter');
  const productIdParam = urlParams.get('productid');

  useEffect(() => {
    async function getSession() {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const accessToken = session.getAccessToken().getJwtToken();
      const refreshToken = session.getRefreshToken().getToken();
      dispatch(loginSuccess({ idToken, accessToken, refreshToken }));
    }
    getSession();
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (filterParam || productIdParam) {
      const filtered = filterDataByParams(response, filterParam, productIdParam);
      setFilteredData(filtered);
    }
  }, [response, filterParam, productIdParam]);

  useEffect(() => {
    const filtered = filterDataByActiveFilters(response, activeFilters, filterCategory);
    setFilteredData(filtered);
  }, [response, activeFilters, filterCategory]);

  useEffect(() => {
    const sorted = sortData(response, sortType);
    setFilteredData(sorted);
  }, [response, sortType]);

  const fetchData = async () => {
    try {
      setLoading(1);
      let response = await getProducts();
      if (!response) return;

      response = sortByIsForSale(response);
      setResponse(response);
      setFilteredData(response);
      setLoading(0);
    } catch (error) {
      setLoading(0);
      console.error(error.message);
    }
  };

  const filterDataByParams = (data, filterParam, productIdParam) => {
    if (!data || data.length === 0) return data;

    const lowerCaseFilterParam = filterParam?.toLowerCase() ?? '';
    const lowerCaseProductIdParam = productIdParam?.toLowerCase() ?? '';

    if (lowerCaseProductIdParam.length > 0) {
      return data.filter(item => item.ProductID?.toString() === lowerCaseProductIdParam);
    }

    return data.filter(item => {
      const lowerCaseValues = Object.values(item).map(value => value?.toString().toLowerCase());
      return lowerCaseValues.some(value => value?.includes(lowerCaseFilterParam));
    });
  };

  const filterDataByActiveFilters = (data, activeFilters, filterCategory) => {
    if (!data || data.length === 0 || !activeFilters || activeFilters.length === 0) return data;

    const lowerCaseActiveFilters = activeFilters.map(filter => filter.toLowerCase());
    if (activeFilters.length > 0 && filterCategory?.length > 0) {
      data = data.filter(item => item?.BrandName?.toLowerCase().includes(filterCategory.toLowerCase()));
    }

    return data.filter(item => {
      const lowerCaseValues = Object.values(item).map(value => value?.toString().toLowerCase());
      return lowerCaseActiveFilters.some(filter =>
        lowerCaseValues.some(value => value?.includes(filter))
      );
    });
  };

  const sortData = (data, sortType) => {
    if (!data || data.length === 0 || !sortType || sortType.length === 0) return data;

    switch (sortType) {
      case 'Price: Low -> High':
        return [...data].sort((a, b) => a.Price - b.Price);
      case 'Price: High -> Low':
        return [...data].sort((a, b) => b.Price - a.Price);
      case 'New -> Old':
        return [...data].sort((a, b) => a.ProductId - b.ProductId); // Replace 'timestamp' with your actual timestamp field
      case 'Old -> New':
        return [...data].sort((a, b) => b.ProductId - a.ProductId); // Replace 'timestamp' with your actual timestamp field
      case 'Title: A-Z':
        return [...data].sort((a, b) => a.ProductName.localeCompare(b.ProductName));
      case 'Title: Z-A':
        return [...data].sort((a, b) => b.ProductName.localeCompare(a.ProductName));
      default:
        return [...data].sort((a, b) => b.IsForSale - a.IsForSale);
    }
  };

  const sortByIsForSale = (data) => {
    return data.sort((a, b) => {
      if ((b.IsForSale ?? 0) < (a.IsForSale ?? 0)) return -1;
      if ((b.IsForSale ?? 0) > (a.IsForSale ?? 0)) return 1;
      return 0;
    });
  };

  const handleQuantityChange = (productId, event) => {
    setQuantities({
      ...quantities,
      [productId]: event.target.value,
    });
  };

  const handleAddToCartClick = (product) => {
    dispatch(
      addToCart({
        item: product,
        quantity: parseInt(quantities[product.Id] || 1, 10),
      })
    );
  };

  return (
    <>
      {loading === 1 && <Spinner />}
      {response && filteredData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            ml: 2,
            mr: 2,
          }}
        >
          <div>
            <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                {filteredData?.map(product => (
                  <ProductCard
                    key={product.Id}
                    product={product}
                    quantities={quantities}
                    handleQuantityChange={handleQuantityChange}
                    handleAddToCartClick={handleAddToCartClick}
                  />
                ))}
              </Grid>
            </Grid>
          </div>
        </Box>
      )}
    </>
  );
};

export default Shopping;
