import React from "react";

function Contact() {
  return (
    <div>
      <h1>Contact</h1>
      <p>
        For all matters please contact us at{" "}
        <a href="mailto:sales@purchaseparadise.com.au">Email us</a>
      </p>
      <p>sales@purchaseparadise.com.au</p>
     <p> <p>For inquiries, call us at:</p>
      <a href="tel:0481282985">0481282985</a>
      </p>
      <p> <p>For international inquiries, call:</p>
      <a href="tel:+61481282985">+61481282985</a>
      </p>
    </div>
  );
}

export default Contact;
